import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

/* const galleries = [
  {
    id: "paintings",
    img:
      "https://www.datocms-assets.com/26440/1587581594-soppenheimoct19012web.jpg",
  },
  { id: "marbling" },
  { id: "Suminagashi" },
  { id: "Sample Book" },
  { id: "Black Light" },
  { id: "Black Hours edition" },
  { id: "Alba Amicorum" },
  { id: "Alba Amicorum zine" },
] */

const BlackWhiteStudies = ({
  data: {
    datoCmsText: {
      contentNode: {
        childMarkdownRemark: { html },
      },
    },
    allDatoCmsGallery: { nodes: galleries },
  },
}) => {
  return (
    <Layout>
      <SEO title="Home" />
      <div
        css={css`
          width: 90%;
          font-family: Karla;
          font-size: 2rem;
          margin: 0px auto;
        `}
      >
        <p dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
        `}
      >
        {galleries.map(gallery => (
          <div
            css={css`
              padding: 5px;
              width: 50%;
              font-family: Karla;
              align-self: stretch;
              margin-bottom: 10%;
              font-size: 1.3rem;
            `}
          >
            <div>
              <Img fluid={gallery.images[0].fluid} />
            </div>
            <div
              css={css`
                font-weight: bold;
              `}
            >
              {gallery.title}
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsText(textid: { eq: "black-and-white-studies" }) {
      id
      contentNode {
        childMarkdownRemark {
          html
        }
      }
    }
    allDatoCmsGallery {
      nodes {
        galleryid
        title
        description
        images {
          originalId
          width
          height
          fluid(maxWidth: 2500) {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  }
`

BlackWhiteStudies.propTypes = {
  data: PropTypes.shape({
    datoCmsText: PropTypes.shape({
      contentNode: PropTypes.shape({
        childMarkdownRemark: PropTypes.shape({
          html: PropTypes.string,
        }),
      }),
    }),
    allDatoCmsGallery: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          images: PropTypes.shape({
            fluid: PropTypes.object.isRequired,
          }).isRequired,
        }).isRequired
      ).isRequired,
    }),
  }).isRequired,
}

export default BlackWhiteStudies
